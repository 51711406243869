import { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageTermsProps {
  className?: string;
}


const PageTerms: FC<PageTermsProps> = ({ className = "" }) => {

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageTerms"
    >
      <Helmet>
        <title>Terms Of Service || Wijhah</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          الشروط والأحكام
        </h2>
        <div className="container mx-auto p-6 text-right" dir="rtl"> 
          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١. مقدمة:</h2>
            <p className="mb-4">
              تأسست مؤسسة موقع وجهة لتقنية المعلومات، مالكة العالمة التجارية (وِجْهَة)، بموجب السجل التجاري رقم 1009128692. حرصاً من وِجْهَة على حماية بيانات ومعلومات المستخدم، تلتزم الشركة بالحفاظ على سرية وخصوصية هذه البيانات واستخدامها بما يتوافق مع الشروط والأحكام المعمول بها في المملكة العربية السعودية. يعد استخدام مواقع وتطبيقات وِجْهَة بمثابة موافقة على سياسة الخصوصية الخاصة بالمستخدم.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٢. البيانات التي سيتم جمعها:</h2>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">
                <span className="font-bold">بيانات شخصية:</span> تتضمن اسم المستخدم، الاسم الكامل، رقم الهوية الوطنية أو الإقامة، البريد الإلكتروني، رقم الهاتف، وتفضيلات الإشعارات.
              </li>
              <li className="mb-2">
                <span className="font-bold">بيانات الدفع:</span> تشمل معلومات الدفع، بيانات بطاقة الائتمان، تاريخ انتهاء صلاحيتها، وأجزاء منها مثل أول ستة أرقام وآخر أربعة أرقام.
              </li>
              <li className="mb-2">
                <span className="font-bold">بيانات الحجز:</span> تتعلق بسجل الحجوزات ورقم تعريف الحجز وتفاصيل الحجوزات.
              </li>
              <li className="mb-2">بيانات ملفات تعريف الارتباط "الكوكيز".</li>
              <li className="mb-2">
                <span className="font-bold">بيانات أخرى:</span> تشمل العنوان، المدينة، نظام التشغيل، الموقع الجغرافي، إعدادات الجهاز.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٣. طرق جمع البيانات:</h2>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">يتم جمع البيانات التي يقوم صاحب البيانات الشخصية بتزويد الموقع بها عن طريق التسجيل أو عندما تقوم بعمليات الحجوزات على المنصة، أو عندما تتفاعل معها.</li>
              <li className="mb-2">يتم جمع بياناتك الشخصية بطريقة غير مباشرة عن طريق ملفات الارتباط التي يتم جمعها عند زيارة الموقع (Cookies).</li>
              <li className="mb-2">تحصل وِجْهَة أيضاً على المعلومات الشخصية بشكل غير مباشر من مصادر مختلفة مثل شركاء وِجْهَة والجهات الحكومية وبعض المصادر الخارجية كمنصات التسويق المخصصة وفقاً لأحكام نظام حماية البيانات الشخصية.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٤. استخدام البيانات الشخصية والغرض:</h2>
            <p className="mb-4">من الضروري لدينا معرفة بعض بيانات العملاء الشخصية، إذا كنت قد قمت بالحجز بالنيابة عن شخص آخر، فإنك تضمن موافقة الشخص الآخر لاستخدام معلوماته الشخصية. وعليه فإن وِجْهَة تجمع البيانات الشخصية للمستخدم من أجل:</p>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">
                <span className="font-bold">كضيف:</span> إجراء واتمام عمليات الحجوزات، ومعاملات الدفع في الخدمات الأساسية المتوفرة على المنصة.
              </li>
              <li className="mb-2">
                <span className="font-bold">كمضيف:</span> إجراء واتمام عمليات إضافة وحدات الضيافة الخاصة، وتوفير معلوماتها وعناوينها في الخدمات الأساسية المتوفرة على المنصة.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٥. تخزين البيانات الشخصية وإتلافها:</h2>
            <p className="mb-4">نستخدم مُعالجي بيانات مختلفين لمعالجة البيانات التشغيلية والتحليلية. ويقوم هؤلاء بمعالجة بياناتك الشخصية وفقاً لمتطلبات الجهات المعنية ومعايير وِجْهَة وليس لديهم أي مطالبات من أي نوع بشأن هذه البيانات.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٦. الأساس النظامي لجمع ومعالجة البيانات الشخصية:</h2>
            <p className="mb-4">يتم جمع ومعالجة البيانات الشخصية بناء على موافقة صاحب البيانات الشخصية، ويمكنه الرجوع عن موافقته على جمع ومعالجة بياناته الشخصية وإتلاف بياناته في أي وقت ما لم يكن هناك أساس نظامي آخر.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٧. حقوق صاحب البيانات الشخصية:</h2>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">
                <span className="font-bold">الحق في العلم:</span> يحق لصاحب البيانات الشخصية معرفة طرق جمعنا لبياناته والأساس النظامي لجمعها ومعالجتها.
              </li>
              <li className="mb-2">
                <span className="font-bold">الحق في الوصول إلى البيانات الشخصية:</span> يحق لصاحب البيانات الشخصية أن يطلب نسخة من بياناته الشخصية.
              </li>
              <li className="mb-2">
                <span className="font-bold">الحق في تصحيح البيانات الشخصية:</span> يحق لصاحب البيانات الشخصية أن يطلب تصحيح بياناته الشخصية.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٨. مشاركة/تبادل البيانات الشخصية:</h2>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">
                <span className="font-bold">سياسات ملفات تعريف الارتباط:</span> لجعل زيارة موقعنا/تطبيقنا أمراً جذاباً، نستخدم ملفات تعريف الارتباط.
              </li>
              <li className="mb-2">
                <span className="font-bold">مشاركة البيانات:</span> نشارك البيانات بعد التأكد من التزام مقدمي الخدمة بسياسات حماية البيانات.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">٩. الروابط الخارجية:</h2>
            <p className="mb-4">في حال تم توفير روابط لمواقع إلكترونية أخرى على موقع (وِجْهَة) مراعاة لاحتياجات المستخدم، لا تعتبر (وِجْهَة) مسؤولة عن أي محتويات موجودة على تلك المواقع.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١٠. ممارسة حقوق صاحب البيانات الشخصية:</h2>
            <p className="mb-4">يحق لصاحب البيانات طلب الوصول/التصحيح/إتلاف بياناته عن طريق التواصل عبر البريد التالي: info@wijhah.net</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١١. مسؤول حماية البيانات الشخصية:</h2>
            <p className="mb-4">الاسم: مسؤول حماية البيانات في الشركة (وِجْهَة)</p>
            <p className="mb-4">البريد الإلكتروني: info@wijhah.net</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١٢. الشكاوى والاستفسارات:</h2>
            <p className="mb-4">في حال وجود شكاوى أو استفسارات متعلقة بسياسة الخصوصية، يرجى التواصل عبر: info@wijhah.net</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١٣. تحديثات سياسة الخصوصية:</h2>
            <p className="mb-4">تم إجراء آخر تحديث على سياسة الخصوصية بتاريخ 30/09/2024م. تحتفظ إدارة موقع الشركة بحق إضافة أو تغيير أي من أحكام سياسة الخصوصية بدون إشعار مسبق.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١٤. إدارة موقع الشركة:</h2>
            <p className="mb-4">اسم الجهة المشرفة على الموقع: مؤسسة موقع وجهة لتقنية المعلومات "وِجْهَة".</p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-bold mb-4">١٥. أحكام عامة:</h2>
            <ul className="list-disc mr-6 mb-4">
              <li className="mb-2">في حال استخدامك لبيانات شخص آخر دون علمه أو موافقته وفقاً لأحكام النظام، فإنك تتحمل كافة الأضرار الناتجة عن استخدامك للبيانات.</li>
              <li className="mb-2">في حال وجود نزاع بين المضيف والضيف على تلفيات أو سوء استخدام عقار المضيف أو مخالفة أي من أنظمة المملكة العربية السعودية، فإنك توافق على مشاركتنا لبياناتك الشخصية مع المضيف والجهات المختصة.</li>
              <li className="mb-2">في حال تعارض بند في هذه السياسة مع أي من أنظمة المملكة، يعد ذلك البند وحده باطلاً وتكون باقي أحكام هذه السياسة نافذة.</li>
              <li className="mb-2">في حال تعارض أي اتفاقية أخرى مع هذه السياسة، فإن أحكام هذه السياسة تسود وتعتبر ملزمة.</li>
              <li className="mb-2">عند الموافقة على سياسة الخصوصية أو تحديثاتها يجب عليك مراجعة السياسة ونقر مربع "أوافق".</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PageTerms;
